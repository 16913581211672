import React from 'react';
import { useNavigate } from 'react-router-dom';
import GreeceMapSvgSelect from '../../components/maps/space-map-select';
import '../../css/spaces-map-select.css';

function SpacesMapSelect() {
    const navigate = useNavigate();

    const handleRegionClick = (region) => {
        navigate(`/spaces?region=${encodeURIComponent(region)}`);
    };

    return (
        <div className="map-svg-select-block">
            <title>Spaces By Region</title>
            <meta property="og:title" content="Mapping Artistic Spaces" key="title" />
            <GreeceMapSvgSelect onRegionClick={handleRegionClick} />
        </div>
    );
}

export default SpacesMapSelect;
