import moment from 'moment'
import {FaCheckCircle, FaTimesCircle} from 'react-icons/fa'
import EditItemIcon from "../../../assets/edit-item.svg";
import DeleteItemIcon from "../../../assets/delete-item.svg";
import {format, isValid} from 'date-fns';


export const ViewOpenCalls = ({
                               data,
                               editHandler,
                               deleteHandler,
                               isLoadingDelete
                           }) => {
    // console.log(data);
    return (
        <div className='table-responsive bg-light p-3 mt-2'>

            <table className='table table-sm table-border opencalls-grid-view-edit'>
                <thead className='border-0'>
                <tr>
                    <th>Created</th>
                    <th>Title</th>
                    <th>Link</th>
                    <th>Date</th>
                    <th>Confirmed</th>
                    <th>Blocked</th>
                    <th>Actions</th>
                </tr>
                </thead>

                <tbody>
                {data && data.data ? (
                    data.data.map((openCall) => (
                        <tr key={openCall._id}
                            className={(openCall.title === '' || openCall.expired_date === '' || openCall.link === '') ? 'empty-values' : ''}>
                            <td>{moment(openCall.createdAt).format('lll')}</td>
                            <td>{openCall.title}</td>
                            <td>{openCall.link}</td>
                            <td>
                                {isValid(new Date(openCall.expired_date))
                                    ? format(new Date(openCall.expired_date), 'yyyy-MM-dd')
                                    : 'Invalid date'
                                }
                            </td>
                            <td>
                                {openCall.confirmed ? (
                                    <FaCheckCircle className='text-success'/>
                                ) : (
                                    <FaTimesCircle className='text-danger'/>
                                )}
                            </td>
                            <td>
                                {openCall.blocked ? (
                                    <FaCheckCircle className='text-success'/>
                                ) : (
                                    <FaTimesCircle className='text-danger'/>
                                )}
                            </td>

                            <td>
                                <div className='btn-group'>
                                    <button
                                        className='btn'
                                        onClick={() => editHandler(openCall)}
                                        data-bs-toggle='modal'
                                        data-bs-target='#openCallModal'
                                    >
                                        <img src={EditItemIcon} alt="Edit"
                                             className="icon-img action-icons-img"/>
                                    </button>

                                    <button
                                        className='btn'
                                        onClick={() => deleteHandler(openCall._id)}
                                        disabled={isLoadingDelete}
                                    >
                                        {isLoadingDelete ? (
                                            <span className='spinner-border spinner-border-sm'/>
                                        ) : (

                                            <img src={DeleteItemIcon} alt="Delete"
                                                 className="icon-img action-icons-img"/>
                                        )}
                                    </button>
                                </div>
                            </td>
                        </tr>
                    ))) : (
                    // Render an error message when data is undefined or has no data
                    <tr>
                        <td colSpan="8">Error: Failed to fetch openCall data</td>
                    </tr>
                )}
                </tbody>
            </table>
        </div>

    )
}

export default ViewOpenCalls
