import {Spinner, Message} from '../../index'
import {getDefaultCategoryOptions} from '../../maps/space-filtering';
import {
    DynamicInputSelect,
    InputCheckBox,
    InputEmail,
    InputText,
    InputTextArea,
} from '../../../utils/dynamicForm'

import {LocationPicker} from '../../../utils/LocationPicker'


const FormSpaces = ({
                        edit,
                        formCleanHandler,
                        isLoading,
                        register,
                        isError,
                        errors,
                        watch,
                        isLoadingUpdate,
                        isLoadingPost,
                        handleSubmit,
                        submitHandler,
                        error,
                        setValue
                    }) => {

    // console.log(getDefaultCategoryOptions());
    const categoryOptions = getDefaultCategoryOptions().map((option, index) => ({
        ...option,
        key: index.toString(),
    }));

    // Get the initial latitude and longitude values for editing
    const initialLat = watch('lat');
    const initialLng = watch('lng');

    // const handleLocationChange = (newPosition) => {
    //     setValue('lat', newPosition.lat); // Set the value of 'lat' Input field
    //     setValue('lng', newPosition.lng); // Set the value of 'lng' Input field
    // };
    return (
        <div
            className='modal fade'
            id='spaceModal'
            data-bs-backdrop='static'
            data-bs-keyboard='false'
            tabIndex='-1'
            aria-labelledby='spaceModalLabel'
            aria-hidden='true'
        >
            <div className='modal-dialog'>
                <div className='modal-content modal-background'>
                    <div className='modal-header'>
                        <h3 className='modal-title ' id='spaceModalLabel'>
                            {edit ? 'Edit Space' : 'Add a NEW Space'}
                        </h3>
                        <button
                            type='button'
                            className='btn-close'
                            data-bs-dismiss='modal'
                            aria-label='Close'
                            onClick={formCleanHandler}
                        ></button>
                    </div>
                    <div className='modal-body'>
                        {isLoading ? (
                            <Spinner/>
                        ) : isError ? (
                            <Message variant='danger'>{error}</Message>
                        ) : (
                            <form onSubmit={handleSubmit(submitHandler)}>

                                <div className='basic-data form-group-block'>
                                    <div className='basic-data-title form-group-title'>ΠΛΗΡΟΦΟΡΙΕΣ</div>
                                    {InputText({
                                        register,
                                        errors,
                                        label: 'Όνομα / Τίτλος',
                                        name: 'name',
                                        placeholder: '',
                                    })}
                                    {InputTextArea({
                                        register,
                                        errors,
                                        label: 'Περιγραφή του Χώρου',
                                        name: 'description',
                                        placeholder: "Example: [Nobody's Theater: A premier theatrical venue in the heart of the city, featuring a grand proscenium stage, modern amenities, and versatile spaces for captivating performances, concerts, and events.]",
                                    })}
                                    {InputText({
                                        register,
                                        errors,
                                        label: 'Note',
                                        name: 'note',
                                        placeholder: "",
                                        isRequired: false,
                                    })}
                                    {InputText({
                                        register,
                                        errors,
                                        label: 'Image',
                                        name: 'image',
                                        placeholder: "",
                                        isRequired: false,
                                    })}
                                </div>
                                <div className='address-data form-group-block'>
                                    <div className='address-data-title form-group-title'>ΣΤΟΙΧΕΙΑ ΔΙΕΥΘΥΝΣΗΣ</div>
                                    {InputText({
                                        register,
                                        errors,
                                        label: 'Διεύθυνση',
                                        name: 'address',
                                        placeholder: 'Καναούλη 12',
                                        isRequired: false,
                                    })}
                                    {InputText({
                                        register,
                                        errors,
                                        label: 'ΤΚ',
                                        name: 'postal',
                                        placeholder: '',
                                        isRequired: false,
                                    })}
                                    {InputText({
                                        register,
                                        errors,
                                        label: 'Όροφος',
                                        name: 'floor',
                                        placeholder: '',
                                        isRequired: false,
                                    })}
                                </div>
                                <div className='contact-data form-group-block'>
                                    <div className='contact-data-title form-group-title'>ΣΤΟΙΧΕΙΑ ΕΠΙΚΟΙΝΩΝΙΑΣ</div>
                                    {InputText({
                                        register,
                                        errors,
                                        label: 'Admin',
                                        name: 'admin1',
                                        placeholder: "",
                                        isRequired: false,
                                    })}
                                    {InputText({
                                        register,
                                        errors,
                                        label: 'Τηλέφωνο Επικοινωνίας',
                                        name: 'phone',
                                        placeholder: '',
                                        isRequired: false,
                                    })}
                                    {InputEmail({
                                        register,
                                        errors,
                                        label: 'Email',
                                        name: 'email',
                                        placeholder: '',
                                        isRequired: false,
                                    })}
                                    {InputText({
                                        register,
                                        errors,
                                        label: 'Website',
                                        name: 'website',
                                        placeholder: "",
                                        isRequired: false,
                                    })}
                                </div>
                                <div className='filtering-data form-group-block'>
                                    <div className='filtering-data-title form-group-title'>FILTERING DATA</div>

                                    {DynamicInputSelect({
                                        register,
                                        errors,
                                        label: 'Category',
                                        name: 'category',
                                        placeholder: "",
                                        isRequired: false,
                                        data: categoryOptions,
                                        value: 'label',
                                        id: 'value',
                                        media_path: '../img/map_icons/',
                                        icons: true,

                                    })}
                                    {InputText({
                                        register,
                                        errors,
                                        label: 'Tags',
                                        name: 'tags',
                                        isRequired: false,
                                        placeholder: "",
                                        validOptions: ["option1", "option2", "option3"],
                                    })}

                                </div>

                                <div className='location-data form-group-block'>
                                    <div className='location-data-title form-group-title'>GPS DATA</div>
                                    {/*{InputText({*/}
                                    {/*  register,*/}
                                    {/*  errors,*/}
                                    {/*  label: 'Google Maps Location',*/}
                                    {/*  name: 'glocation',*/}
                                    {/*  placeholder: "https://goo.gl/maps/a5by2tSsL9dh3eRP6",*/}
                                    {/*  isRequired: false,*/}
                                    {/*})}*/}
                                    {LocationPicker({
                                        register,
                                        errors,
                                        watch,
                                        setValue,
                                        isRequired: false,
                                        placeholder: "",
                                        initialLat, // Pass the initial latitude value as a prop
                                        initialLng, // Pass the initial longitude value as a prop
                                        // onChange: handleLocationChange, // Pass the handleLocationChange callback to the locationPicker component
                                    })}
                                    {/*{InputText({*/}
                                    {/*  register,*/}
                                    {/*  errors,*/}
                                    {/*  label: 'latitude',*/}
                                    {/*  name: 'lat',*/}
                                    {/*  placeholder: "40.636384",*/}
                                    {/*  isRequired: false,*/}
                                    {/*})}*/}
                                    {/*{InputText({*/}
                                    {/*  register,*/}
                                    {/*  errors,*/}
                                    {/*  label: 'longitude',*/}
                                    {/*  name: 'lng',*/}
                                    {/*  placeholder: "22.9512836",*/}
                                    {/*  isRequired: false,*/}
                                    {/*})}*/}


                                </div>
                                {InputCheckBox({
                                    register,
                                    errors,
                                    watch,
                                    name: 'confirmed',
                                    label: 'Confirmed',
                                    isRequired: false,
                                    placeholder: 'Confirmed',
                                })}

                                {InputCheckBox({
                                    register,
                                    errors,
                                    watch,
                                    name: 'blocked',
                                    label: 'Blocked',
                                    isRequired: false,
                                    placeholder: 'Blocked',
                                })}
                                <div className='modal-footer'>
                                    <button
                                        type='button'
                                        className='btn btn-secondary '
                                        data-bs-dismiss='modal'
                                        onClick={formCleanHandler}
                                    >
                                        Close
                                    </button>
                                    <button
                                        type='submit'
                                        className='btn btn-primary '
                                        disabled={isLoadingPost || isLoadingUpdate}
                                    >
                                        {isLoadingPost || isLoadingUpdate ? (
                                            <span className='spinner-border spinner-border-sm'/>
                                        ) : (
                                            'Submit'
                                        )}
                                    </button>
                                </div>
                            </form>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FormSpaces
