import React, {useState, useEffect} from 'react'
import {Helmet, HelmetProvider} from 'react-helmet-async'
import {format} from 'date-fns';
import {confirmAlert} from 'react-confirm-alert'
import {useForm} from 'react-hook-form'
import useOpenCallsHook from '../../../api/openCalls'
// import {ErrorBoundary} from "react-error-boundary";
// import {useNavigate} from 'react-router-dom';
import {ViewOpenCalls} from '../../../components/admin/openCalls/ViewOpenCalls';
import {FormOpenCalls} from '../../../components/admin/openCalls/FormOpenCalls';
import {
    Spinner,
    Pagination,
    Message,
    Confirm, Search,
} from '../../../components'
// import {Button} from "@mui/material";

const OpenCalls = () => {
    const [page, setPage] = useState(1)
    const [id, setId] = useState(null)
    const [edit, setEdit] = useState(false)
    const [q, setQ] = useState('')

    const {getOpenCalls, postOpenCall, updateOpenCall, deleteOpenCall} = useOpenCallsHook({
        page,
        q,
    })

    const {
        register,
        handleSubmit,
        watch,
        setValue,
        reset,
        formState: {errors},
    } = useForm({
        defaultValues: {
            confirmed: true,
            blocked: false,
        },
    })

    const {
        data,
        isLoading,
        isError,
        error,
        refetch
    } = getOpenCalls

    const {
        isLoading: isLoadingUpdate,
        isSuccess: isSuccessUpdate,
        mutateAsync: mutateAsyncUpdate,
    } = updateOpenCall

    const {
        isLoading: isLoadingDelete,
        mutateAsync: mutateAsyncDelete,
    } = deleteOpenCall

    const {
        isLoading: isLoadingPost,
        isSuccess: isSuccessPost,
        mutateAsync: mutateAsyncPost,
    } = postOpenCall

    const formCleanHandler = () => {
        setEdit(false);
        reset();  // Reset form via react-hook-form
    };

    useEffect(() => {
        if (isSuccessPost || isSuccessUpdate) {
            setEdit(false);
        }
    }, [isSuccessPost, isSuccessUpdate])

    useEffect(() => {
        if (q.trim() || page) {
            refetch();
        }
    }, [page, q, refetch]);

    const searchHandler = (e) => {
        e.preventDefault()
        refetch()
        setPage(1)
    }

    const deleteHandler = (id) => {
        confirmAlert(Confirm(() => mutateAsyncDelete(id)))
    }

    const submitHandler = async (data) => {
        try {
            if (edit) {
                await mutateAsyncUpdate({
                    _id: id,
                    title: data.title,
                    description: data.description,
                    link: data.link,
                    image: data.image,
                    tags: data.tags,
                    expired_date: data.expired_date,
                    confirmed: data.confirmed,
                    blocked: data.blocked

                });
            } else {
                await mutateAsyncPost(data);
            }
        } catch (error) {
            console.error("Submission Error:", error);
        }
    };

    const editHandler = (openCall) => {

        // console.log(openCall)

        const expired_date = Date.parse(openCall.expired_date);
        const formattedExpired_date = isNaN(expired_date) ? null : format(new Date(expired_date), 'yyyy-MM-dd');



        setId(openCall._id)
        setEdit(true)
        setValue('title', openCall.title)
        setValue('description', openCall.description)
        setValue('link', openCall.link)
        setValue('image', openCall.image)
        setValue('tags', openCall.tags)
        setValue('expired_date', formattedExpired_date)
        setValue('confirmed', openCall.confirmed)
        setValue('blocked', openCall.blocked)
    }

    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <title>OpenCalls</title>
                    <meta property='og:title' content='OpenCalls' key='title'/>
                </Helmet>
            </HelmetProvider>
            <div className='view-list-top-bar'>

                <h3 className='fw-light text-muted text-center mb-3'>
                    OpenCalls List{' '}
                    <sup className='fs-6'> [{data && data.total}] </sup>
                </h3>

                <div className='d-flex align-items-center justify-content-between mb-2'>
                    <button
                        className='btn add-new-entry-button align-self-end'
                        data-bs-toggle='modal'
                        data-bs-target='#openCallModal'
                        onClick={() => formCleanHandler(true)}
                    >
                        Add New OpenCall
                    </button>
                    <Pagination data={data} setPage={setPage}/>
                </div>

                <div className='col-auto full-search'>
                    <Search
                        placeholder='Search by Title'
                        setQ={setQ}
                        q={q}
                        searchHandler={searchHandler}
                    />
                </div>
            </div>


            {isLoading ? (
                <Spinner/>
            ) : isError ? (
                <Message variant='danger'>{error}</Message>
            ) : (
                <ViewOpenCalls
                    data={data}
                    editHandler={editHandler}
                    deleteHandler={deleteHandler}
                    isLoadingDelete={isLoadingDelete}
                />
            )}
            <div className='pagination-bottom-block'>
                <Pagination data={data} setPage={setPage}/>
            </div>
            <FormOpenCalls
                edit={edit}
                formCleanHandler={formCleanHandler}
                isLoading={isLoading}
                setValue={setValue}
                isError={isError}
                errors={errors}
                isLoadingUpdate={isLoadingUpdate}
                isLoadingPost={isLoadingPost}
                register={register}
                handleSubmit={handleSubmit}
                submitHandler={submitHandler}
                watch={watch}
                error={error}
            />
        </>
    )
}

export default OpenCalls
