import React, { useState, useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { confirmAlert } from 'react-confirm-alert';
import { useForm } from 'react-hook-form';
import usePermissionsHook from '../../../api/permissions';
import {Spinner, ViewPermissions, Pagination, FormPermissions, Confirm, Message, Search} from '../../../components';

const Permissions = () => {
  const [page, setPage] = useState(1);
  const [id, setId] = useState(null);
  const [edit, setEdit] = useState(false);
  const [q, setQ] = useState('');

  const { getPermissions, postPermission, updatePermission, deletePermission } = usePermissionsHook({ page, q });
  const { register, handleSubmit, setValue, watch, reset, formState: { errors } } = useForm({
    defaultValues: { auth: true },
  });

  const { data, isLoading, isError, error, refetch } = getPermissions;
  const { mutateAsync: mutateAsyncUpdate, isSuccess: isSuccessUpdate } = updatePermission;
  const { mutateAsync: mutateAsyncDelete } = deletePermission;
  const { mutateAsync: mutateAsyncPost, isSuccess: isSuccessPost } = postPermission;


  const formCleanHandler = () => {
    setEdit(false);
    reset();  // Reset form via react-hook-form
  };

  useEffect(() => {
    if (isSuccessPost || isSuccessUpdate) {
      setEdit(false);
    }
  }, [isSuccessPost, isSuccessUpdate])

  useEffect(() => {
    if (q.trim() || page) {
      refetch();
    }
  }, [page, q, refetch]);

  const searchHandler = (e) => {
    e.preventDefault();
    refetch();
    setPage(1);
  };

  const deleteHandler = (id) => {
    confirmAlert(Confirm(() => mutateAsyncDelete(id)));
  };

  const submitHandler = (data) => {
    if (edit) {
      mutateAsyncUpdate({ _id: id, ...data });
    } else {
      mutateAsyncPost(data);
    }
  };

  const editHandler = (permission) => {
    setId(permission._id);
    setEdit(true);
    setValue('name', permission.name);
    setValue('description', permission.description);
    setValue('method', permission.method);
    setValue('route', permission.route);
    setValue('auth', permission.auth);
  };

  return (
      <>
        <HelmetProvider>
          <Helmet>
            <title>Permissions</title>
            <meta property='og:title' content='Permissions' key='title'/>
          </Helmet>
        </HelmetProvider>

        <div className='view-list-top-bar'>

          <h3 className='fw-light text-muted text-center mb-3'>
            Permissions List <sup className='fs-6'> [{data && data.total}] </sup>
          </h3>

          <div className='d-flex align-items-center justify-content-between mb-2'>
            <button
                className='btn add-new-entry-button align-self-end'
                data-bs-toggle='modal'
                data-bs-target='#permissionModal'
                onClick={() => formCleanHandler(true)}
            >
              Add New Permission
            </button>
            <Pagination data={data} setPage={setPage}/>
          </div>

          <div className='col-auto full-search'>
            <Search
                placeholder='Search by name'
                setQ={setQ}
                q={q}
                searchHandler={searchHandler}
            />
          </div>
        </div>

        {isLoading ? (
            <Spinner/>
        ) : isError ? (
            <Message variant='danger'>{error}</Message>
        ) : (
            <ViewPermissions
                data={data}
                editHandler={editHandler}
                deleteHandler={deleteHandler}
            />
        )}
        <div className='pagination-bottom-block'>
          <Pagination data={data} setPage={setPage}/>
        </div>
        <FormPermissions
            edit={edit}
            formCleanHandler={formCleanHandler}
            isLoading={isLoading}
            isError={isError}
            errors={errors}
            isLoadingUpdate={isLoading}
            isLoadingPost={isLoading}
            register={register}
            handleSubmit={handleSubmit}
            submitHandler={submitHandler}
            watch={watch}
            error={error}
        />

      </>
  );
};

export default Permissions;
