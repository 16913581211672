import React, {useState} from 'react';
import {Grid} from "@mui/material";
import {useTranslation} from 'react-i18next';

const DefaultMarkerIcon = window.location.origin + "/img/event_icons/default.svg";

import EventsCategoryFilter, {getDefaultEventsCategoryOptions} from "./events-filtering";

function EventsList({eventsData}) {
    const {t} = useTranslation();
    const [selectedCategory, setSelectedCategory] = useState('');

    function getImageSource(category) {
        if (category) {
            const domain = window.location.origin;
            return `${domain}/img/event_icons/${category}.svg`;
        }
        return DefaultMarkerIcon;
    }

    function isDateToday(date) {
        const today = new Date();
        const formattedDate = new Date(date);
        return (
            formattedDate.getDate() === today.getDate() &&
            formattedDate.getMonth() === today.getMonth() &&
            formattedDate.getFullYear() === today.getFullYear()
        );
    }

    function isDateTomorrow(date) {
        const tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);
        const formattedDate = new Date(date);
        return (
            formattedDate.getDate() === tomorrow.getDate() &&
            formattedDate.getMonth() === tomorrow.getMonth() &&
            formattedDate.getFullYear() === tomorrow.getFullYear()
        );
    }

    function formatDate(date) {
        return date.toLocaleDateString(undefined, {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric'
        });
    }

    function extractMonth(date) {
        return date.toLocaleDateString(undefined, {month: 'long'});
    }

    function extractWeekday(date) {
        return date.toLocaleDateString(undefined, {weekday: 'long', day: 'numeric'});
    }

    const groupedEvents = {};
    // Filter out events that have already passed
    const yesterday = new Date();
    yesterday.setHours(yesterday.getHours() - 6);

    const fifteenDaysFromNow = new Date();
    fifteenDaysFromNow.setDate(fifteenDaysFromNow.getDate() + 15);

    // Filter events based on the selected category and group them also sort them
    eventsData.data.forEach(event => {
        // if no category is selected or if it matches the selected category
        if (new Date(event.dateEnd) > yesterday && (selectedCategory == "" || selectedCategory.includes(event.category))) {
            let currentDate = new Date(event.dateStart);
            let endDate = new Date(event.dateEnd);

            // Trim dates that are outside the range
            if (currentDate < yesterday) {
                currentDate = new Date(yesterday);
            }
            if (endDate > fifteenDaysFromNow) {
                endDate = new Date(fifteenDaysFromNow);
            }

            while (currentDate <= endDate) {
                const formattedDate = formatDate(currentDate);
                const month = extractMonth(currentDate);
                const weekday = extractWeekday(currentDate);
                if (!groupedEvents[formattedDate]) {
                    groupedEvents[formattedDate] = {
                        events: [],
                        isToday: isDateToday(currentDate),
                        isTomorrow: isDateTomorrow(currentDate),
                        date: formattedDate,
                        month: month,
                        weekday: weekday
                    };
                }
                groupedEvents[formattedDate].events.push(event);
                currentDate.setDate(currentDate.getDate() + 1);
            }
        }
    });

    // Sort the array based on the date in each entry
    const groupedEventsArray = Object.entries(groupedEvents).sort(
        ([dateA], [dateB]) => new Date(dateA) - new Date(dateB)
    );


    function handleCategoryChange(category) {
        // console.log('cat' + category)
        // Set selectedCategory to null if the category is an empty string
        setSelectedCategory(category);
    }

    // Global map of how many times we've shown each event so far:
    const eventCountMap = new Map();

    return (
        <div>
            <div className="events-list-title">{t('Events')}</div>
            <div className="events-list-section">
                <Grid gridRow={1} sx={{width: '100%', height: '100%'}}>
                    <Grid sm={12}>
                        <div className="events-list-block">
                            {groupedEventsArray.length === 0 ? (
                                <div className="no-events-message">
                                    {t("Is it possible for nothing to be happening in Thessaloniki, despite having 1 million registered people?")}
                                </div>
                            ) : (
                                groupedEventsArray.map(([date, {events, isToday, isTomorrow, weekday, month}]) => (
                                    <div className="events-list-by-date" key={date}>
                                        <div className="events-list-date-title">
                                            <div className="event-month">{t(month)}</div>
                                            <div className="event-day">{t(weekday)}</div>
                                            {isToday && <div className="event-today">{t("Today")}</div>}
                                            {isTomorrow && <div className="event-tomorrow">{t("Tomorrow")}</div>}
                                        </div>
                                        <div className="events-list-date-items">
                                            {events.map((event) => {
                                                console.log('Event ID:', event._id);
                                                // How many times have we shown this event so far (in the entire list)?
                                                const countSoFar = eventCountMap.get(event._id) || 0;
                                                // It's "overused" if we've shown it at least once before.
                                                const isOverused = countSoFar > 0;

                                                // Now increment its count
                                                eventCountMap.set(event._id, countSoFar + 1);

                                                const itemClass = `events-list-item${isOverused ? " overused-entry" : ""}`;

                                                return (
                                                    <div className={itemClass} key={event._id}>
                                                        <img
                                                            className="events-list-item-category-icon"
                                                            src={getImageSource(event.category)}
                                                            alt="Category"
                                                        />
                                                        <div className="events-list-item-time">
                                                            {event.time}
                                                        </div>
                                                        <a
                                                            className="events-list-item-link"
                                                            href={event.link}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >
                                                            {event.title}
                                                        </a>
                                                        {event.glocation && (
                                                            <a
                                                                className="events-list-item-location"
                                                                href={event.glocation}
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                            >
                                                                <img
                                                                    className="events-list-item-location-icon"
                                                                    src={DefaultMarkerIcon}
                                                                    alt="Default Marker Icon"
                                                                />
                                                            </a>
                                                        )}
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                ))
                            )}
                        </div>
                    </Grid>
                    <Grid sm={12}></Grid>
                </Grid>
            </div>
            <div className="events-filters-section">
                <div className="events-filters-title">{t("Filters")}</div>
                <div className="events-filters-block">
                    <EventsCategoryFilter
                        options={getDefaultEventsCategoryOptions()}
                        onChange={handleCategoryChange}
                    />
                </div>
            </div>
        </div>
    );
}

export default EventsList;