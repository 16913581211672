import React from 'react';
import Select from 'react-select';

const AddSpacesToUser = ({ name, errors, dataMachines, setValue, selectedMachines, setSelectedMachines }) => {
    // Handle the change in selection
    const handleSelectChange = (selectedOptions) => {
        setSelectedMachines(selectedOptions || []);
        const machineIds = selectedOptions ? selectedOptions.map(option => option.value) : [];
        setValue(name, machineIds);
    };

    // Map dataMachines to options for react-select
    const options = dataMachines?.data?.map(machine => ({
        value: machine._id,
        label: machine.machine_name,
    })) || [];

    return (
        <div className='mb-3'>
            <label htmlFor='machines'>Select Machines</label>
            <Select
                id='machines'
                options={options}
                isMulti
                onChange={handleSelectChange}
                value={selectedMachines}
                className={`form-control ${errors[name] ? 'is-invalid' : ''}`}
            />
            {errors[name] && <div className='invalid-feedback'>{errors[name].message}</div>}
        </div>
    );
};

export default AddSpacesToUser;
