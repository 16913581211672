import React from 'react';

function OpenCallsList({openCallsData,t}) {

    function formatDate(date) {
        return date.toLocaleDateString(undefined, {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric'
        });
    }

    // Filter and sort open calls
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);

    const sortedOpenCalls = openCallsData.data
        .filter(openCall => {
            const expiredDate = new Date(openCall.expired_date);
            return expiredDate > yesterday; // Filter out expired open calls
        })
        .sort((a, b) => new Date(a.expired_date) - new Date(b.expired_date)); // Sort by expiration date

    return (
            <div className="open-calls-list-section">
                <div className="open-calls-list-block">
                    <div className="open-calls-list-title">{t('Open Calls')}</div>
                    {sortedOpenCalls.length === 0 ? (
                        <div className="no-open-calls-message">{t("No open calls available")}</div>
                    ) : (
                        sortedOpenCalls.map((openCall) => (
                            <div className="open-calls-list-item" key={openCall.id}>

                                <a
                                    className="open-calls-list-item-link"
                                    href={openCall.link}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {openCall.title}
                                </a>
                                <div className="open-calls-list-date-expires-block">
                                    {t("Expires on")}: <div className="open-calls-list-date-expires">{formatDate(new Date(openCall.expired_date))}</div>
                                </div>
                            </div>
                        ))
                    )}
                </div>

            </div>
    );
}

export default OpenCallsList;