import React from 'react';
import "../../css/open-calls-view.css";
import dynamicAPI from '../../api/dynamicAPI';
import {useQuery} from 'react-query';
import OpenCallsList from "../../components/open-calls/opencalls-list";
import OpenCallsForm from "../../components/open-calls/opencalls-form";
import {useTranslation} from "react-i18next";

function OpenCallsView() {
    const url = '/api/view/opencalls';
    const q = '';
    const queryKey = 'open_calls';
    const {data: openCallsData, error: eventsError} = useQuery(
        queryKey,
        async () =>
            await dynamicAPI('get', `${url}?page=1&q=${q}&limit=1000`, {}),
        {retry: 0}
    );
    const { t } = useTranslation();

    // console.log('heyu');

    // console.log(eventsData);

    // console.log(spacesData);

    // Check if the data has been fetched successfully
    if (eventsError) {
        return <div>Error: {eventsError.message}</div>;
    }

    if (!openCallsData || !Array.isArray(openCallsData.data)) {
        return <div>No events found.</div>;
    }
    return (
        <>
            <title>Find what is going on</title>
            <meta property="og:title" content="No open calls at this time" key="title"/>

            <div className="open-calls-block" style={{ width: '100%'}}>
                    <OpenCallsList
                        openCallsData={openCallsData}
                        t={t}
                    />
                    <OpenCallsForm
                        t={t}
                    />
            </div>
        </>
    );
}

export default OpenCallsView;