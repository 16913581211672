import dynamicAPI from './dynamicAPI';
import { useMutation } from 'react-query';
import { useMessage } from '../context/AdminMessageContext';

export default function useBotHook() {
    const { setSuccessMessage, setErrorMessage } = useMessage();

    const initiateBot = useMutation(
        async () => await dynamicAPI('post', '/api/bot/webhookInitiate', {}),
        {
            retry: 0,
            onSuccess: (data) => {
                if (data.message) {
                    setSuccessMessage(data.message);
                } else {
                    setErrorMessage('Bot initiated, but no message received.');
                }
            },
            onError: (error) => {
                const errorMessage = error?.response?.data?.error || 'An error occurred while initiating the bot.';
                setErrorMessage(errorMessage);
            },
        }
    );

    return {
        initiateBot,
    };
}
