import React from 'react';
import useBotHook from '../../../api/bot';
import '../../../css/BotSettings.css';

const BotSettings = () => {
    const { initiateBot } = useBotHook();

    const handleInitiateBot = () => {
        initiateBot.mutate();
    };

    return (
        <div className="bot-settings">
            <h1>Bot Settings</h1>

            {/* Button to initiate the bot */}
            <button
                onClick={handleInitiateBot}
                disabled={initiateBot.isLoading}
            >
                {initiateBot.isLoading ? 'Initiating...' : 'Initiate Bot'}
            </button>

            {/* Display status */}
            {initiateBot.isSuccess && (
                <p className="success-message">Bot initiated successfully!</p>
            )}
            {initiateBot.isError && (
                <p className="error-message">Failed to initiate the bot. Please try again.</p>
            )}
        </div>
    );
};

export default BotSettings;
