import React, { useState, useRef } from "react";
import {useForm} from "react-hook-form";
import ReCAPTCHA from "react-google-recaptcha";
import {GoogleCaptchaKey} from "../../config/client-config";
import {
    InputDate,
    InputText, InputUrl
} from '../../utils/dynamicForm';

import "../../css/open-call-form.css";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const apiUrl = process.env.REACT_APP_BACKEND_URL || "https://api.aart.gr";
const RECAPTCHA_SITE_KEY = GoogleCaptchaKey;

function OpenCallsForm({t}) {
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset
    } = useForm();


    const [showForm, setShowForm] = useState(true);
    const [message, setMessage] = useState(null);
    const recaptchaRef = useRef(null);

    // Function to handle form submission
    const onSubmit = async (data) => {
        const recaptchaToken = await recaptchaRef.current.executeAsync();

        // if (!recaptchaRef.current) {
        //     console.error("reCAPTCHA ref is not set");
        //     return;
        // }
        // if (!recaptchaToken) {
        //     console.error("reCAPTCHA failed to return a token");
        //     return;
        // }

        // Create the request options object for the POST request to the API
        const requestOptions = {
            method: "POST",
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify({ recaptchaToken, data }),
        };

        // Send the POST request to the API using fetch
        const response = await fetch(apiUrl + '/api/submit/open-calls', requestOptions);

        // Handle the response and handle any errors
        if (response.ok) {
            // Success
            setShowForm(false);
            const responseData = await response.json();
            setMessage(responseData.message || t("Submitted but with some errors!"));
            toast.success(t("Thank you for submitting this open call, we will need sometime to verify it!"));
            // Reset form values
            reset();
        } else {
            // Error
            const errorData = await response.json();
            setMessage(errorData.message || "An error occurred");
        }
    };

    const handleButtonClick = () => {
        setShowForm(!showForm);
    };
    const handleCloseButtonClick = () => {
        handleButtonClick();
    };
    return (
        <div
            className={
                showForm ? "open-call-form-container" : "open-call-form-container add-form"
            }
        >
            <div className="open-call-form-add-background-block"></div>

            <div className="open-call-form-add-button-block">
                <button className="open-call-add-form-button" onClick={handleButtonClick}>
                    {t("+")}
                </button>
            </div>

            <div className="open-call-form">
                <ToastContainer />
                <div className="open-call-form-block">
                    <div className="open-call-form-close-button-block">
                        <div className="open-call-form-close-button" onClick={handleCloseButtonClick}>X</div>
                    </div>
                    <div className="open-call-form-title">{t("New Open Call")}</div>

                    <form onSubmit={handleSubmit(onSubmit)}>

                        <div className="open-call-form-block-recaptcha">
                            <ReCAPTCHA
                                ref={recaptchaRef}
                                size="invisible"
                                sitekey={RECAPTCHA_SITE_KEY}
                            />
                        </div>

                        {InputText({
                            register,
                            errors,
                            label: 'Open_Call_Form_Title',
                            name: 'title',
                            placeholder: "",
                            isRequired: true,
                        })}
                        {/*{errors.title && <span className="submit-error-validation">Title is required</span>}*/}
                        {InputUrl({
                            register,
                            errors,
                            label: 'Open_Call_Form_Link',
                            name: 'link',
                            placeholder: "",
                            isRequired: true,
                        })}
                        {/*{errors.link && <span className="submit-error-validation">Link is required</span>}*/}
                        {InputDate({
                            register,
                            errors,
                            label: 'Open_Call_Form_Expired_Date',
                            name: 'expired_date',
                            placeholder: "",
                            isRequired: true,
                            value: 'label',
                        })}
                        <>
                            {message &&
                                <p className={message.includes("added") ? "success-message" : "error-message"}>{message}</p>}
                        </>
                        <div className="open-call-submit-form-buttons">
                            <div className="cancel-button-open-call-form"
                                 onClick={handleCloseButtonClick}>{t("Cancel")}
                            </div>
                            <input className="open-call-submit-button"
                                   type="submit"
                                   value={t("Submit")}
                            />
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default OpenCallsForm;
