import { useEffect } from 'react'
import { FormContainer, Message } from '../../components'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import { InputPassword } from '../../utils/dynamicForm'
import useAuthHook from '../../api/auth'
import useAuth from '../../hooks/useAuth'
import { Helmet, HelmetProvider } from 'react-helmet-async'

const ResetPassword = () => {
  const { postResetPassword } = useAuthHook()
  const param = useParams()
  const navigate = useNavigate()
  const { auth } = useAuth()

  const { resetToken } = param

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      admin: false,
      user: false,
    },
  })

  const { isLoading, isError, error, isSuccess, mutateAsync } =
      postResetPassword

  useEffect(() => {
    if (isSuccess) {
      navigate('/admin/auth/login')
    }
  }, [isSuccess, navigate])

  // Effect to check user authentication status and navigate accordingly
  useEffect(() => {
    if (auth?.userInfo) {
      navigate('/');
    }
  }, [auth?.userInfo, navigate]);  // Add auth.userInfo and navigate as dependencies


  const submitHandler = (data) => {
    const password = data.password
    mutateAsync({ password, resetToken })
  }

  return (
      <FormContainer>
        <HelmetProvider>
          <Helmet>
            <title>Reset</title>
            <meta property='og:title' content='Reset' key='title' />
          </Helmet>
        </HelmetProvider>
        <h3 className=''>Reset Password</h3>
        {isSuccess && (
            <Message variant='success'>Password Updated Successfully</Message>
        )}

        {isError && <Message variant='danger'>{error}</Message>}

        <form onSubmit={handleSubmit(submitHandler)}>
          {InputPassword({
            register,
            errors,
            label: 'Password',
            name: 'password',
            minLength: true,
            isRequired: true,
            placeholder: 'Password',
          })}

          {InputPassword({
            register,
            errors,
            watch,
            name: 'confirmPassword',
            label: 'Confirm Password',
            validate: true,
            minLength: true,
            placeholder: 'Confirm Password',
          })}

          <button
              type='submit'
              className='btn btn-primary form-control'
              disabled={isLoading}
          >
            {isLoading ? (
                <span className='spinner-border spinner-border-sm' />
            ) : (
                'Change'
            )}
          </button>
        </form>
      </FormContainer>
  )
}

export default ResetPassword
