import dynamicAPI from './dynamicAPI';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useMessage } from '../context/AdminMessageContext';

const url = '/api/auth/user-roles';
const queryKey = 'userRoles';

const useUserRolesHook = (props) => {
    const { page = 1, id, q = '', limit = 25 } = props;
    const queryClient = useQueryClient();
    const { setSuccessMessage, setErrorMessage } = useMessage(); // Use the message context

    const getUserRoles = useQuery(
        [queryKey, page, limit],
        async () => {
            const response = await dynamicAPI('get', `${url}?page=${page}&q=${q}&limit=${limit}`);
            if (response.error) {
                const errorMessage = response.error || 'An error occurred while fetching the user-roles.';
                setErrorMessage(errorMessage);
            } else {
                return response;
            }
        },
        {
            retry: 0,
            staleTime: 1000 * 15,
        }
    );

    const getUserRoleById = useQuery(
        [queryKey, id],
        async () => {
            const response = await dynamicAPI('get', `${url}/${id}`);
            if (response.error) {
                const errorMessage = response.error || 'An error occurred while fetching the user-role.';
                setErrorMessage(errorMessage);
            } else {
                return response;
            }
        },
        { retry: 0, enabled: !!id }
    );

    const updateUserRole = useMutation(
        async (obj) => await dynamicAPI('put', `${url}/${obj._id}`, obj),
        {
            retry: 0,
            onSuccess: (data) => {
                if (data.isSuccess) {
                    setSuccessMessage('UserRole updated successfully.');
                    queryClient.invalidateQueries([queryKey]);
                } else {
                    const errorMessage = data.error || 'Failed to update UserRole.';
                    setErrorMessage(errorMessage);
                }
            },
            onError: (error) => {
                const errorMessage = error?.error || 'An error occurred while updating UserRole.';
                setErrorMessage(errorMessage);
            },
        }
    );

    const deleteUserRole = useMutation(
        async (id) => await dynamicAPI('delete', `${url}/${id}`, {}),
        {
            retry: 0,
            onSuccess: (data) => {
                if (data.isSuccess) {
                    setSuccessMessage('UserRole deleted successfully.');
                    queryClient.invalidateQueries([queryKey]);
                } else {
                    const errorMessage = data.error || 'Failed to delete UserRole.';
                    setErrorMessage(errorMessage);
                }
            },
            onError: (error) => {
                const errorMessage = error?.error || 'An error occurred while deleting UserRole.';
                setErrorMessage(errorMessage);
            },
        }
    );

    const postUserRole = useMutation(
        async (obj) => await dynamicAPI('post', url, obj),
        {
            retry: 0,
            onSuccess: (data) => {
                if (data.isSuccess) {
                    setSuccessMessage('UserRole created successfully.');
                    queryClient.invalidateQueries([queryKey]);
                } else {
                    const errorMessage = data.error || 'Failed to create UserRole.';
                    setErrorMessage(errorMessage);
                }
            },
            onError: (error) => {
                const errorMessage = error?.error || 'An error occurred while creating UserRole.';
                setErrorMessage(errorMessage);
            },
        }
    );

    return {
        getUserRoles,
        updateUserRole,
        deleteUserRole,
        postUserRole,
        getUserRoleById,
    };
};

export default useUserRolesHook;
