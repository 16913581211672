import React, {useState} from "react";
import {useJsApiLoader, GoogleMap, Marker, Autocomplete} from "@react-google-maps/api";
import "../css/locationHelper.css";
import {GoogleMapsAPIKey} from "../config/client-config";

const googleMapLibrariesForPicker = ["drawing", "places", "geometry"];


export const LocationPicker = (args) => {
    const { register, errors, isRequired = false , initialLat, initialLng, watch, setValue} = args

    const {isLoaded} = useJsApiLoader({
        googleMapsApiKey: GoogleMapsAPIKey,
        libraries: googleMapLibrariesForPicker
        // ...otherOptions
    });

    const [mapState, setMapState] = useState({
        center: {
            lat: 40.629269,
            lng: 22.947412
        },
        zoom: 7
    });
    // const [setSelectedLocation] = useState(
    // {
    //         lat: initialLat,
    //         lng: initialLng,
    //     });

    // console.log('selectedLocation');
    // console.log(selectedLocation);
    console.log('initialat');
    console.log(initialLat);
    console.log('initialng');
    console.log(initialLng);


    const manageMap = (newState) =>
        setMapState((prevState) => ({...prevState, ...newState}));

    if (!isLoaded) {
        return null;
    }

    const {center, zoom} = mapState;

    const handleAutocomplete = (place) => {
        console.log('autocomplete')

        console.log('PLACE');

        console.log(place);
        const newPosition = { lat: place.lat, lng: place.lng };
        manageMap({ center: newPosition, zoom: 16 });

        console.log('new position');
        console.log(newPosition)
        // setSelectedLocation(newPosition);
        setValue("lat",newPosition.lat);
        setValue("lng",newPosition.lng);
        // const latInput = document.getElementById("lat");
        // lngInput = document.getElementById("lng");
        // lngInput.value = place.lng;

        // Trigger input event
        // const inputEvent = new Event("input", { bubbles: true });
        // latInput.dispatchEvent(inputEvent);
        // lngInput.dispatchEvent(inputEvent);
    };

    const handleMarkerDragEnd = (event) => {
        const newPosition = {
            lat: event.latLng.lat(),
            lng: event.latLng.lng()
        };
        console.log('DRAG')
        console.log(newPosition)
        manageMap({ center: newPosition });
        // setSelectedLocation(newPosition);
        setValue("lat",newPosition.lat);
        setValue("lng",newPosition.lng);
        // Update input values
        // const latInput = document.getElementById("latInput");
        // const lngInput = document.getElementById("lngInput");
        // Trigger input event
        // const inputEvent = new Event("input", { bubbles: true });
        // latInput.dispatchEvent(inputEvent);
        // lngInput.dispatchEvent(inputEvent);
    };


    let currentlat = watch("lat");
    let currentlng = watch("lng");


    return (
        <div className='location-picker-box'>
            <div className='location-picker-autocomplete'>
                <GoogleMapsAutocomplete handleAutocomplete={handleAutocomplete}/>
            </div>
            <div className='location-picker-map'>
                <GoogleMap
                    mapContainerStyle={{
                        height: "250px",
                        width: "auto",
                        position: "relative",
                        marginBottom: "15px",
                    }}
                    center={center}
                    zoom={zoom}
                >
                    <div className='location-picker-map-marker'>
                        {center && (
                            <Marker
                                position={center}
                                draggable={true}
                                onDragEnd={handleMarkerDragEnd}
                            />
                        )}
                    </div>
                </GoogleMap>
            </div>
            <div className='mb-3'>
                <label htmlFor='lat'>Latitude</label>
                <input
                    {...register('lat', isRequired && { required: `Latitude is required` })}
                    type='text'
                    placeholder=''
                    value={currentlat}
                    // value={selectedLocation.lat !== null ? selectedLocation.lat : null}
                    // defaultValue={initialLat}
                    className='form-control'
                    id="lat"
                />
                {errors && errors['lat'] && (
                    <span className='text-danger'>{errors['lat'].message}</span>
                )}
            </div>
            <div className='mb-3'>
                <label htmlFor='lng'>Longitude</label>
                <input
                    {...register('lng', isRequired && { required: `Longitude is required` })}
                    type='text'
                    placeholder=''
                    value={currentlng}
                    // defaultValue={selectedLocation.lng !== null ? selectedLocation.lng : initialLng}
                    className='form-control'
                    id="lng"
                />
                {errors && errors['lng'] && (
                    <span className='text-danger'>{errors['lng'].message}</span>
                )}
            </div>
        </div>
    );
};


const GoogleMapsAutocomplete = ({handleAutocomplete}) => {
    const [autocomplete, setAutocomplete] = useState();

    const onLoad = (loaded) => setAutocomplete(loaded);

    const onPlaceChanged = () => {
        const place = autocomplete.getPlace();
        if (place.geometry && place.geometry.location) {
            const newPosition = {
                lat: place.geometry.location.lat(),
                lng: place.geometry.location.lng()
            };
            handleAutocomplete(newPosition);
        }
    };

    return (
            <div className='map-autocomplete-input'>
                <Autocomplete onLoad={onLoad}
                              onPlaceChanged={onPlaceChanged}
                              options={{
                                  componentRestrictions: {country: 'gr'}
                              }}>
                        <input
                            name="find_a_place"
                            type="text"
                            placeholder="Find a place"
                            style={autoCompleteStyles}
                            className='form-control'
                        />
                </Autocomplete>
            </div>
    );
};

const autoCompleteStyles = {
    // boxSizing: `border-box`,
    // width: `100%`,
    // height: `32px`,
    // padding: `0 12px`,
    // border: `1px solid gray`,
    // boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
    // fontSize: `14px`,
    // outline: `none`,
    // textOverflow: `ellipses`,
    // position: "absolute",
    // top: "0px",
};
