import { Spinner, Message } from '../../index'
import {
  InputDate,
  InputCheckBox,
  InputText,
  InputUrl
} from '../../../utils/dynamicForm';


export const FormOpenCalls = ({
  edit,
  formCleanHandler,
  isLoading,
  register,
  isError,
  errors,
  watch,
  isLoadingUpdate,
  isLoadingPost,
  handleSubmit,
  submitHandler,
  error,
}) => {



  return (
    <div
      className='modal fade'
      id='openCallModal'
      data-bs-backdrop='static'
      data-bs-keyboard='false'
      tabIndex='-1'
      aria-labelledby='openCallModalLabel'
      aria-hidden='true'
    >
      <div className='modal-dialog'>
        <div className='modal-content modal-background'>
          <div className='modal-header'>
            <h3 className='modal-title ' id='openCallModalLabel'>
              {edit ? 'Edit Events' : 'Add a NEW Event'}
            </h3>
            <button
              type='button'
              className='btn-close'
              data-bs-dismiss='modal'
              aria-label='Close'
              onClick={formCleanHandler}
            ></button>
          </div>
          <div className='modal-body'>
            {isLoading ? (
              <Spinner />
            ) : isError ? (
              <Message variant='danger'>{error}</Message>
            ) : (
              <form onSubmit={handleSubmit(submitHandler)}>
                <div className='basic-data form-group-block'>
                  <div className='basic-data-title form-group-title'>ΠΛΗΡΟΦΟΡΙΕΣ</div>
                  {InputText({
                    register,
                    errors,
                    label: 'Όνομα / Τίτλος',
                    name: 'title',
                    placeholder: "",
                    isRequired: true,
                  })}
                  {InputUrl({
                    register,
                    errors,
                    label: 'Link',
                    name: 'link',
                    placeholder: "",
                    isRequired: true,
                  })}
                  {InputDate({
                    register,
                    errors,
                    label: 'Expired Date',
                    name: 'expired_date',
                    placeholder: "",
                    isRequired: true,
                    value: 'label',
                    id: 'value'
                  })}

                </div>

                {InputCheckBox({
                  register,
                  errors,
                  watch,
                  name: 'confirmed',
                  label: 'Confirmed',
                  isRequired: false,
                  placeholder: 'Confirmed',
                })}

                {InputCheckBox({
                  register,
                  errors,
                  watch,
                  name: 'blocked',
                  label: 'Blocked',
                  isRequired: false,
                  placeholder: 'Blocked',
                })}
                <div className='modal-footer'>
                  <button
                    type='button'
                    className='btn btn-secondary '
                    data-bs-dismiss='modal'
                    onClick={formCleanHandler}
                  >
                    Close
                  </button>
                  <button
                    type='submit'
                    className='btn btn-primary '
                    disabled={isLoadingPost || isLoadingUpdate}
                  >
                    {isLoadingPost || isLoadingUpdate ? (
                      <span className='spinner-border spinner-border-sm' />
                    ) : (
                      'Submit'
                    )}
                  </button>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default FormOpenCalls
