import dynamicAPI from './dynamicAPI';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useMessage } from '../context/AdminMessageContext';

const url = '/api/auth/open-calls';
const queryKey = 'open-calls';

export default function useOpenCallHook(props) {
    const { page = 1, id, q = '', limit = 25 } = props;
    const queryClient = useQueryClient();
    const { setSuccessMessage, setErrorMessage } = useMessage(); // Use the message context

    const getOpenCalls = useQuery(
        [queryKey, page, limit],
        async () => {
            const response = await dynamicAPI('get', `${url}?page=${page}&q=${q}&limit=${limit}`);
            if (response.error) {
                const errorMessage = response.error || 'An error occurred while fetching the opencalls.';
                setErrorMessage(errorMessage);
            } else {
                return response;
            }
        },
        {
            retry: 0,
            staleTime: 1000 * 15,
        }
    );

    const getOpenCallById = useQuery(
        [queryKey, id],
        async () => {
            const response = await dynamicAPI('get', `${url}/${id}`);
            if (response.error) {
                const errorMessage = response.error || 'An error occurred while fetching the opencall.';
                setErrorMessage(errorMessage);
            } else {
                return response;
            }
        },
        { retry: 0, enabled: !!id }
    );

    const updateOpenCall = useMutation(
        async (obj) => await dynamicAPI('put', `${url}/${obj._id}`, obj),
        {
            retry: 0,
            onSuccess: (data) => {
                if (data.isSuccess) {
                    setSuccessMessage('OpenCall updated successfully.');
                    queryClient.invalidateQueries([queryKey]);
                } else {
                    const errorMessage = data.error || 'Failed to update opencall.';
                    setErrorMessage(errorMessage);
                }
            },
            onError: (error) => {
                const errorMessage = error?.error || 'An error occurred while updating the opencall.';
                setErrorMessage(errorMessage);
            }
        }
    );

    const deleteOpenCall = useMutation(
        async (id) => await dynamicAPI('delete', `${url}/${id}`, {}),
        {
            retry: 0,
            onSuccess: (data) => {
                if (data.isSuccess) {
                    setSuccessMessage('OpenCall deleted successfully.');
                    queryClient.invalidateQueries([queryKey]);
                } else {
                    const errorMessage = data.error || 'Failed to delete opencall.';
                    setErrorMessage(errorMessage);
                }
            },
            onError: (error) => {
                const errorMessage = error?.error || 'An error occurred while deleting the opencall.';
                setErrorMessage(errorMessage);
            }
        }
    );

    const postOpenCall = useMutation(
        async (obj) => await dynamicAPI('post', url, obj),
        {
            retry: 0,
            onSuccess: (data) => {
                if (data.isSuccess) {
                    setSuccessMessage('OpenCall created successfully.');
                    queryClient.invalidateQueries([queryKey]);
                } else {
                    const errorMessage = data.error || 'Failed to create opencall.';
                    setErrorMessage(errorMessage);
                }
            },
            onError: (error) => {
                const errorMessage = error?.error || 'An error occurred while creating the opencall.';
                setErrorMessage(errorMessage);
            }
        }
    );

    return {
        getOpenCalls,
        updateOpenCall,
        deleteOpenCall,
        postOpenCall,
        getOpenCallById,
    };
}
