import React, {useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import "../../css/language-switcher.css";
import {languageOptions} from "../../config/languagesConfig";

function LanguageSwitcherDropdown() {
    const {i18n} = useTranslation();
    const [showDropdown, setShowDropdown] = useState(false);

    // Initialize selectedLanguage state based on i18n's current language
    const getLanguageOption = (language) =>
        languageOptions.find(option => option.value === language || language.startsWith(option.value)) || languageOptions[0];


    // Ensure the correct language option is used on initialization
    const [selectedLanguage, setSelectedLanguage] = useState(() => getLanguageOption(i18n.language || 'en'));


    useEffect(() => {
        // Sync the selectedLanguage with i18n.language changes
        const currentLanguageOption = getLanguageOption(i18n.language);
        setSelectedLanguage(currentLanguageOption);
    }, [i18n.language]); // Re-run effect when i18n.language changes

    const changeLanguage = (language) => {
        i18n.changeLanguage(language); // Updates the i18n language
        setSelectedLanguage(getLanguageOption(language)); // Updates the selected icon and label
        setShowDropdown(false); // Close the dropdown
    };

    const toggleDropdown = () => {
        setShowDropdown(!showDropdown);
    };

    return (
        <div className="language-selector">
            <div className="dropdown-container">
                <div className="selected-option" onClick={toggleDropdown}>
                    <div className="dropdown-option-icon">
                        {selectedLanguage.icon}
                    </div>
                    <div className="dropdown-option-label">
                        {selectedLanguage.label}
                    </div>
                </div>

                {showDropdown && (
                    <div className="options-container">
                        {languageOptions.map((option) => (
                            <div
                                key={option.value}
                                className="option"
                                onClick={() => changeLanguage(option.value)}
                            >
                                {option.icon}
                                {option.label}
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
}






export function LanguageSwitcherIcons() {
    const { i18n } = useTranslation();

    const getLanguageOption = (language) =>
        languageOptions.find(option => option.value === language) || languageOptions[0];

    const getCurrentLanguageOption = useCallback(() =>
        getLanguageOption(i18n.language), [i18n.language]);

    const [selectedLanguage, setSelectedLanguage] = useState(getCurrentLanguageOption());

    useEffect(() => {
        // Sync the component's state with i18n's current language whenever it changes
        setSelectedLanguage(getCurrentLanguageOption());
    }, [ getCurrentLanguageOption]);

    const changeLanguage = (language) => {
        i18n.changeLanguage(language);
        setSelectedLanguage(getLanguageOption(language));
    };

    return (
        <div className="language-icons-container">
            {languageOptions.map((option) => (
                <div
                    key={option.value}
                    className={`language-icon ${selectedLanguage === option.value ? 'selected' : ''}`}
                    onClick={() => changeLanguage(option.value)}
                    title={option.label}
                >
                    {option.icon}
                </div>
            ))}
        </div>
    );
}








export default LanguageSwitcherDropdown;
