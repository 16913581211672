import { Spinner, Message } from '../../index'
import {
  InputText,
  InputTextArea,
  InputMultipleCheckBox,
} from '../../../utils/dynamicForm'

const FormRoles = ({
  edit,
  formCleanHandler,
  isLoading,
  register,
  isError,
  errors,
  isLoadingUpdate,
  isLoadingPost,
  handleSubmit,
  submitHandler,
  error,
  permissionData,
  clientPermissionData,
}) => {

  // Sort permissionData by item.method
  const sortedPermissionData = permissionData && permissionData.sort((a, b) => {
    return a.method.localeCompare(b.method);
  });

  // Sort clientPermissionData by item.path
  const sortedClientPermissionData = clientPermissionData && clientPermissionData.sort((a, b) => {
    return a.menu.localeCompare(b.menu);
  });


  return (
    <div
      className='modal fade'
      id='roleModal'
      data-bs-backdrop='static'
      data-bs-keyboard='false'
      tabIndex='-1'
      aria-labelledby='roleModalLabel'
      aria-hidden='true'
    >
      <div className='modal-dialog modal-lg'>
        <div className='modal-content modal-background'>
          <div className='modal-header'>
            <h3 className='modal-title ' id='roleModalLabel'>
              {edit ? 'Edit Role' : 'Post Role'}
            </h3>
            <button
              type='button'
              className='btn-close'
              data-bs-dismiss='modal'
              aria-label='Close'
              onClick={formCleanHandler}
            ></button>
          </div>
          <div className='modal-body'>
            {isLoading ? (
              <Spinner />
            ) : isError ? (
              <Message variant='danger'>{error}</Message>
            ) : (
              <form onSubmit={handleSubmit(submitHandler)}>
                {InputText({
                  register,
                  errors,
                  label: 'Name',
                  name: 'name',
                  placeholder: 'Name',
                })}

                {InputTextArea({
                  register,
                  errors,
                  label: 'Description',
                  name: 'description',
                  placeholder: 'Description',
                })}

                <div className='border border-secondary p-2'>
                  <label className="roleLabel" htmlFor='permission'>API Permission</label>
                  {InputMultipleCheckBox({
                    register,
                    errors,
                    label: 'Permission',
                    name: 'permission',
                    placeholder: 'Permission',
                    data:
                        sortedPermissionData &&
                        sortedPermissionData.map((item) => ({
                        name: `${item.method} - ${item.description}`,
                        _id: item._id,
                      })),
                    isRequired: false,
                  })}
                </div>

                <div className='border border-secondary p-2 mt-2'>
                  <label className="roleLabel" htmlFor='clientPermission'>Client Permission</label>
                  {InputMultipleCheckBox({
                    register,
                    errors,
                    label: 'Client Permission',
                    name: 'clientPermission',
                    placeholder: 'Client Permission',
                    data:
                        sortedClientPermissionData &&
                        sortedClientPermissionData.map((item) => ({
                        name: `${item.menu} - ${item.path}`,
                        _id: item._id,
                      })),
                    isRequired: false,
                  })}
                </div>

                <div className='modal-footer'>
                  <button
                    type='button'
                    className='btn btn-secondary '
                    data-bs-dismiss='modal'
                    onClick={formCleanHandler}
                  >
                    Close
                  </button>
                  <button
                    type='submit'
                    className='btn btn-primary '
                    disabled={isLoadingPost || isLoadingUpdate}
                  >
                    {isLoadingPost || isLoadingUpdate ? (
                      <span className='spinner-border spinner-border-sm' />
                    ) : (
                      'Submit'
                    )}
                  </button>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default FormRoles
