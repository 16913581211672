import { Spinner, Message } from '../../index'
import { DynamicInputSelect } from '../../../utils/dynamicForm'

const FormUserRoles = ({
  edit,
  formCleanHandler,
  isLoading,
  register,
  isError,
  errors,
  isLoadingUpdate,
  isLoadingPost,
  handleSubmit,
  submitHandler,
  error,
  dataRoles,
  dataUsers,
}) => {

  const isSubmitting = isLoadingPost || isLoadingUpdate; // Consolidate loading states
  // console.log(dataUsers);

  return (
    <div
      className='modal fade'
      id='userRoleModal'
      data-bs-backdrop='static'
      data-bs-keyboard='false'
      tabIndex='-1'
      aria-labelledby='userRoleModalLabel'
      aria-hidden='true'
    >
      <div className='modal-dialog'>
        <div className='modal-content modal-background'>
          <div className='modal-header'>
            <h3 className='modal-title ' id='userRoleModalLabel'>
              {edit ? 'Edit UserRole' : 'Post UserRole'}
            </h3>
            <button
              type='button'
              className='btn-close'
              data-bs-dismiss='modal'
              aria-label='Close'
              onClick={formCleanHandler}
            ></button>
          </div>
          <div className='modal-body'>
            {isLoading ? (
              <Spinner />
            ) : isError ? (
              <Message variant='danger'>{error}</Message>
            ) : (
              <form onSubmit={handleSubmit(submitHandler)}>
                {DynamicInputSelect({
                  register,
                  errors,
                  label: 'User',
                  name: 'user',
                  value: 'name',
                  data:
                      dataUsers?.data?.filter(
                          (user) => user.confirmed && !user.blocked
                      ),
                  placeholder: 'User',
                })}

                {DynamicInputSelect({
                  register,
                  errors,
                  label: 'Role',
                  name: 'role',
                  placeholder: 'Role',
                  data: dataRoles?.data,
                  value: 'name',
                })}

                <div className='modal-footer'>
                  <button
                      type='button'
                      className='btn btn-secondary '
                      data-bs-dismiss='modal'
                      onClick={formCleanHandler}
                  >
                    Close
                  </button>
                  <button
                      type='submit'
                      className='btn btn-primary'
                      disabled={isSubmitting}
                  >
                    {isSubmitting ? (
                        <span className='spinner-border spinner-border-sm'/>
                    ) : (
                        'Submit'
                    )}
                  </button>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default FormUserRoles
