import dynamicAPI from './dynamicAPI';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useMessage } from '../context/AdminMessageContext';

const url = '/api/auth/spaces';
const queryKey = 'spaces';

export default function useSpaceHook(props) {
    const { page = 1, id, q = '', limit = 25 } = props;
    const queryClient = useQueryClient();
    const { setSuccessMessage, setErrorMessage } = useMessage(); // Use the message context

    const getSpaces = useQuery(
        [queryKey, page, limit],
        async () => {
            const response = await dynamicAPI('get', `${url}?page=${page}&q=${q}&limit=${limit}`);
            if (response.error) {
                const errorMessage = response.error || 'An error occurred while fetching the spaces.';
                setErrorMessage(errorMessage);
            } else {
                return response;
            }
        },
        {
            retry: 0,
            staleTime: 1000 * 15,
        }
    );

    const getSpaceById = useQuery(
        [queryKey, id],
        async () => {
            const response = await dynamicAPI('get', `${url}/${id}`);
            if (response.error) {
                const errorMessage = response.error || 'An error occurred while fetching the space.';
                setErrorMessage(errorMessage);
            } else {
                return response;
            }
        },
        { retry: 0, enabled: !!id }
    );

    const updateSpace = useMutation(
        async (obj) => await dynamicAPI('put', `${url}/${obj._id}`, obj),
        {
            retry: 0,
            onSuccess: (data) => {
                if (data.isSuccess) {
                    setSuccessMessage('Space updated successfully.');
                    queryClient.invalidateQueries([queryKey]);
                } else {
                    const errorMessage = data.error || 'Failed to update space.';
                    setErrorMessage(errorMessage);
                }
            },
            onError: (error) => {
                const errorMessage = error?.error || 'An error occurred while updating the space.';
                setErrorMessage(errorMessage);
            }
        }
    );

    const deleteSpace = useMutation(
        async (id) => await dynamicAPI('delete', `${url}/${id}`, {}),
        {
            retry: 0,
            onSuccess: (data) => {
                if (data.isSuccess) {
                    setSuccessMessage('Space deleted successfully.');
                    queryClient.invalidateQueries([queryKey]);
                } else {
                    const errorMessage = data.error || 'Failed to delete space.';
                    setErrorMessage(errorMessage);
                }
            },
            onError: (error) => {
                const errorMessage = error?.error || 'An error occurred while deleting the space.';
                setErrorMessage(errorMessage);
            }
        }
    );

    const postSpace = useMutation(
        async (obj) => await dynamicAPI('post', url, obj),
        {
            retry: 0,
            onSuccess: (data) => {
                if (data.isSuccess) {
                    setSuccessMessage('Space created successfully.');
                    queryClient.invalidateQueries([queryKey]);
                } else {
                    const errorMessage = data.error || 'Failed to create space.';
                    setErrorMessage(errorMessage);
                }
            },
            onError: (error) => {
                const errorMessage = error?.error || 'An error occurred while creating the space.';
                setErrorMessage(errorMessage);
            }
        }
    );

    return {
        getSpaces,
        updateSpace,
        deleteSpace,
        postSpace,
        getSpaceById,
    };
}
