import moment from 'moment'
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa'
import EditItemIcon from "../../../assets/edit-item.svg";
import DeleteItemIcon from "../../../assets/delete-item.svg";


const ViewSpaces = ({
  data,
  editHandler,
  deleteHandler,
  isLoadingDelete
}) => {
  // console.log(data);
  return (
    <div className='table-responsive bg-light p-3 mt-2'>
      <table className='table table-sm table-border edit-grid-view'>
        <thead className='border-0'>
          <tr>
            <th>Joined Date</th>
            <th>Name</th>
            <th>Address</th>
            <th>Phone</th>
            <th>Email</th>
            <th>Category</th>
            <th>Tags</th>
            <th>Confirmed</th>
            <th>Blocked</th>
            <th>Actions</th>
          </tr>
        </thead>

        <tbody>
        {data && data.data ? (
            data.data.map((space) => (
              <tr key={space._id} className={(space.name === '' || space.lng === '' || space.lat === '') ? 'empty-values' : ''}>
                <td>{moment(space.createdAt).format('lll')}</td>
                <td>{space.name}</td>
                <td>{space.address}</td>

                <td>{space.phone}</td>
                <td>
                  <a href={`mailto:${space.email}`}>{space.email}</a>
                </td>
                <td>{space.category}</td>
                <td>{space.tags}</td>

                <td>
                  {space.confirmed ? (
                    <FaCheckCircle className='text-success' />
                  ) : (
                    <FaTimesCircle className='text-danger' />
                  )}
                </td>
                <td>
                  {space.blocked ? (
                    <FaCheckCircle className='text-success' />
                  ) : (
                    <FaTimesCircle className='text-danger' />
                  )}
                </td>

                <td>
                  <div className='btn-group'>
                    <button
                        className='btn'
                        onClick={() => editHandler(space)}
                        data-bs-toggle='modal'
                        data-bs-target='#spaceModal'
                    >
                      <img src={EditItemIcon} alt="Edit"
                           className="icon-img action-icons-img"/>
                    </button>

                    <button
                        className='btn '
                        onClick={() => deleteHandler(space._id)}
                      disabled={isLoadingDelete}
                    >
                      {isLoadingDelete ? (
                        <span className='spinner-border spinner-border-sm' />
                      ) : (
                          <img src={DeleteItemIcon} alt="Delete"
                               className="icon-img action-icons-img"/>
                      )}
                    </button>
                  </div>
                </td>
              </tr>
            ))) : (
            // Render an error message when data is undefined or has no data
            <tr>
              <td colSpan="8">Error: Failed to fetch space data</td>
            </tr>
        )}
        </tbody>
      </table>
    </div>
  )
}

export default ViewSpaces
