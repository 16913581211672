import { Spinner, Message } from '../../index'
import {getDefaultEventsCategoryOptions} from '../../events/events-filtering';
import {
  DynamicInputSelect,
  InputCheckBox,
  InputText,
  // InputTextArea,
  // dynamicInputMultiSelectBuildIn,
  InputDateRangeMulti,
  InputTime,
  InputUrl
} from '../../../utils/dynamicForm';
import {LocationLinker} from '../../../utils/LocationLinker';


const categoryOptions = getDefaultEventsCategoryOptions().map((option, index) => ({
  ...option,
  key: index.toString(),
}));
// const tagOptions = getDefaultEventsCategoryOptions().map((option, index) => ({
//   ...option,
//   key: index.toString(),
// }));
export const FormEvents = ({
  edit,
  formCleanHandler,
  isLoading,
  register,
  setValue,
  isError,
  errors,
  watch,
  isLoadingUpdate,
  isLoadingPost,
  handleSubmit,
  submitHandler,
  error,
}) => {

  // console.log(getDefaultCategoryOptions());
  // const categoryOptions = getDefaultCategoryOptions().map((option, index) => ({
  //   ...option,
  //   key: index.toString(),
  // }));


  return (
    <div
      className='modal fade'
      id='eventModal'
      data-bs-backdrop='static'
      data-bs-keyboard='false'
      tabIndex='-1'
      aria-labelledby='eventModalLabel'
      aria-hidden='true'
    >
      <div className='modal-dialog'>
        <div className='modal-content modal-background'>
          <div className='modal-header'>
            <h3 className='modal-title ' id='eventModalLabel'>
              {edit ? 'Edit Events' : 'Add a NEW Event'}
            </h3>
            <button
              type='button'
              className='btn-close'
              data-bs-dismiss='modal'
              aria-label='Close'
              onClick={formCleanHandler}
            ></button>
          </div>
          <div className='modal-body'>
            {isLoading ? (
              <Spinner />
            ) : isError ? (
              <Message variant='danger'>{error}</Message>
            ) : (
              <form onSubmit={handleSubmit(submitHandler)}>
                <div className='basic-data form-group-block'>
                  <div className='basic-data-title form-group-title'>ΠΛΗΡΟΦΟΡΙΕΣ</div>
                  {InputText({
                    register,
                    errors,
                    label: 'Όνομα / Τίτλος',
                    name: 'title',
                    placeholder: "",
                    isRequired: true,
                  })}
                  {InputDateRangeMulti({
                    register,
                    errors,
                    setValue,
                    watch,
                    label: 'Date',
                    nameStart: 'dateStart',
                    nameEnd: 'dateEnd',
                    placeholder: 'Ημερομηνία',
                    isRequired: true,
                  })}
                  {InputTime({
                    register,
                    errors,
                    label: 'Time',
                    name: 'time',
                    placeholder: "",
                    isRequired: true,
                  })}
                  {InputUrl({
                    register,
                    errors,
                    label: 'Link',
                    name: 'link',
                    placeholder: "",
                    isRequired: false,
                  })}
                  {DynamicInputSelect({
                    register,
                    errors,
                    label: 'Category',
                    name: 'category',
                    data: categoryOptions,
                    placeholder: "",
                    isRequired: true,
                    value: 'label',
                    id: 'value'
                  })}
                  {LocationLinker({
                    register,
                    setValue,
                    errors,
                    watch,
                    label: 'Google Maps Location Link',
                    name: 'glocation',
                    placeholder: "example: https://goo.gl/maps/a5by2tSsL9dh3eRP6",
                    isRequired: false,
                  })}

                </div>
                {/*<div className='basic-data form-group-block with-space'>*/}
                {/*  <div className='basic-data-title form-group-title'>AKOMA ΔΕΝ ΧΡΗΣΙΜΟΠΟΙΟΥΝΤΑΙ</div>*/}
                {/*  {InputTextArea({*/}
                {/*    register,*/}
                {/*    errors,*/}
                {/*    label: 'Περιγραφή του Event',*/}
                {/*    name: 'description',*/}
                {/*    placeholder: "",*/}
                {/*    isRequired: false,*/}
                {/*  })}*/}
                {/*  {InputText({*/}
                {/*    register,*/}
                {/*    errors,*/}
                {/*    label: 'Διεύθυνση',*/}
                {/*    name: 'address',*/}
                {/*    placeholder: '',*/}
                {/*    isRequired: false,*/}
                {/*  })}*/}
                {/*  {InputText({*/}
                {/*    register,*/}
                {/*    errors,*/}
                {/*    label: 'Image',*/}
                {/*    name: 'image',*/}
                {/*    placeholder: "",*/}
                {/*    isRequired: false,*/}
                {/*  })}*/}
                {/*  {DynamicInputMultiSelectBuildIn({*/}
                {/*    register,*/}
                {/*    errors,*/}
                {/*    label: 'Tags',*/}
                {/*    name: 'tags',*/}
                {/*    data: tagOptions,*/}
                {/*    placeholder: "",*/}
                {/*    isRequired: false,*/}
                {/*    value: 'label'*/}
                {/*  })}*/}
                {/*</div>*/}

                {InputCheckBox({
                  register,
                  errors,
                  watch,
                  name: 'confirmed',
                  label: 'Confirmed',
                  isRequired: false,
                  placeholder: 'Confirmed',
                })}

                {InputCheckBox({
                  register,
                  errors,
                  watch,
                  name: 'blocked',
                  label: 'Blocked',
                  isRequired: false,
                  placeholder: 'Blocked',
                })}
                <div className='modal-footer'>
                  <button
                    type='button'
                    className='btn btn-secondary '
                    data-bs-dismiss='modal'
                    onClick={formCleanHandler}
                  >
                    Close
                  </button>
                  <button
                    type='submit'
                    className='btn btn-primary '
                    disabled={isLoadingPost || isLoadingUpdate}
                  >
                    {isLoadingPost || isLoadingUpdate ? (
                      <span className='spinner-border spinner-border-sm' />
                    ) : (
                      'Submit'
                    )}
                  </button>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default FormEvents
