import React, { useState, useRef } from "react";
// import {getDefaultCategoryOptions} from './space-filtering';
import {useForm} from "react-hook-form";
import ReCAPTCHA from "react-google-recaptcha";
import {GoogleCaptchaKey} from "../../config/client-config";
import {
    DynamicInputSelect,
    InputDateRangeMulti,
    InputText, InputTime, InputUrl
    // dynamicInputSelect,
} from '../../utils/dynamicForm';

import "../../css/event-form.css";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {getDefaultEventsCategoryOptions} from "./events-filtering";
import { useTranslation } from 'react-i18next';

const apiUrl = process.env.REACT_APP_BACKEND_URL || "https://api.aart.gr";
const RECAPTCHA_SITE_KEY = GoogleCaptchaKey;


const categoryOptions = getDefaultEventsCategoryOptions().map((option, index) => ({
    ...option,
    key: index.toString(),
}));
function EventsListForm() {
    const {
        register,
        handleSubmit,
        watch,
        setValue,
        formState: { errors },
        reset
    } = useForm();

    const { t } = useTranslation();

    const [showForm, setShowForm] = useState(true);
    const [message, setMessage] = useState(null);
    const recaptchaRef = useRef(null);

    // Function to handle form submission
    const onSubmit = async (data) => {

        const recaptchaToken = await recaptchaRef.current.executeAsync();

        // Create the request options object for the POST request to the API
        const requestOptions = {
            method: "POST",
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify({ recaptchaToken, data }),
        };

        // Send the POST request to the API using fetch
        const response = await fetch(apiUrl + '/api/submit/events', requestOptions);

        // Handle the response and handle any errors
        if (response.ok) {
            // Success
            setShowForm(false);
            const responseData = await response.json();
            setMessage(responseData.message || t("Submitted but with some errors!"));
            toast.success(t("Thank you for submitting this event, we will need sometime to verify it!"));
            // Reset form values
            reset();
        } else {
            // Error
            const errorData = await response.json();
            setMessage(errorData.message || "An error occurred");
        }
    };

    const handleButtonClick = () => {
        setShowForm(!showForm);
    };
    const handleCloseButtonClick = () => {
        handleButtonClick();
    };
    return (
        <div
            className={
                showForm ? "event-form-container" : "event-form-container add-form"
            }
        >
            <div className="event-form-add-background-block"></div>

            <div className="event-form-add-button-block">
                <button className="event-add-form-button" onClick={handleButtonClick}>
                    {t("Add an Event +")}
                </button>
            </div>

            <div className="event-form">
                <ToastContainer />
                <div className="event-form-block">
                    <div className="event-form-title">{t("New Event")}</div>
                    <div className="event-form-close-button" onClick={handleCloseButtonClick}>X</div>

                    <form onSubmit={handleSubmit(onSubmit)}>

                        <div className="event-form-block-recaptcha">
                            <ReCAPTCHA
                                ref={recaptchaRef}
                                size="invisible"
                                sitekey={RECAPTCHA_SITE_KEY}
                            />
                        </div>

                        {/*<div>*/}
                        {/*    <label htmlFor="name">Τίτλος του event</label>*/}
                        {/*    <Input*/}
                        {/*        placeholder=""*/}
                        {/*        type="text"*/}
                        {/*        {...register("name", {required: true})}*/}
                        {/*        maxLength={50}*/}
                        {/*    />*/}
                        {/*    {errors.name && <span className="submit-error-validation">Name is required</span>}*/}
                        {/*</div>*/}


                        {/*/!*{DynamicInputSelect({*!/*/}
                        {/*/!*    register,*!/*/}
                        {/*/!*    errors,*!/*/}
                        {/*/!*    label: 'Category',*!/*/}
                        {/*/!*    name: 'category',*!/*/}
                        {/*/!*    placeholder: '',*!/*/}
                        {/*/!*    isRequired: true,*!/*/}
                        {/*/!*    data: categoryOptions,*!/*/}
                        {/*/!*    value: 'label',*!/*/}
                        {/*/!*    id: 'value',*!/*/}
                        {/*/!*    media_path: '../img/map_icons/',*!/*/}
                        {/*/!*    icons: true,*!/*/}
                        {/*/!*})}*!/*/}

                        {/*<div>*/}
                        {/*    <label htmlFor="link">Link του event</label>*/}
                        {/*    <Input placeholder="" {...register("link", {required: true})}*/}
                        {/*           maxLength={500}/>*/}
                        {/*    {errors.glocation && <span className="submit-error-validation">Link is required</span>}*/}
                        {/*</div>*/}

                        {/*<div>*/}
                        {/*    <label htmlFor="glocation">Google Maps Location</label>*/}
                        {/*    <Input placeholder="https://goo.gl/maps/a5by2tSsL9dh3eRP6" {...register("glocation", {required: true})}*/}
                        {/*           maxLength={500}/>*/}
                        {/*    {errors.glocation && <span className="submit-error-validation">Google Maps Location is required</span>}*/}

                        {/*</div>*/}

                        {InputText({
                            register,
                            errors,
                            label: t('Title'),
                            name: 'title',
                            placeholder: "",
                            isRequired: true,
                        })}
                        {InputDateRangeMulti({
                            register,
                            errors,
                            setValue,
                            watch,
                            label: t('Date'),
                            nameStart: 'dateStart',
                            nameEnd: 'dateEnd',
                            placeholder: 'Ημερομηνία',
                            isRequired: true,
                        })}
                        {InputTime({
                            register,
                            errors,
                            label: 'Time',
                            name: 'time',
                            placeholder: "",
                            isRequired: true,
                        })}
                        {InputUrl({
                            register,
                            errors,
                            label: 'Link',
                            name: 'link',
                            placeholder: "",
                            isRequired: false,
                        })}
                        {DynamicInputSelect({
                            register,
                            errors,
                            label: t('Category'),
                            name: 'category',
                            data: categoryOptions,
                            placeholder: "",
                            isRequired: true,
                            value: 'label',
                            id: 'value'
                        })}
                        {InputUrl({
                            register,
                            errors,
                            label: t('Google Maps Location Link'),
                            name: 'glocation',
                            placeholder: "example: https://goo.gl/maps/a5by2tSsL9dh3eRP6",
                            isRequired: false,
                        })}

                        {/*<div>*/}
                        {/*  <label htmlFor="email">Email</label>*/}
                        {/*  <input*/}
                        {/*    placeholder="info@google.com"*/}
                        {/*    type="email"*/}
                        {/*    {...register("email")}*/}
                        {/*  />*/}
                        {/*</div>*/}
                        <>
                            {message && <p className={message.includes("added") ? "success-message" : "error-message"}>{message}</p>}
                        </>
                        <input type="submit" value={t("Submit")}/>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default EventsListForm;
